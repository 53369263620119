import React, { useState } from 'react';
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Navbar, Dropdown } from 'react-bootstrap';

import { destroySession } from "../../state/ducks/admin/actions";
import {logoutUser} from "../../state/ducks/auth/operations"
import { showToast } from "../../state/ducks/utils/operations";

import "./AdminHeader.scss";
import Constant from '../../util/constant';
import ModalPopup from '../../components/ModalPopup/ModalPopup';


const AdminHeader = (props) => {
  let { strings } = props;
  let [showProfile] = useState(false)
  let [showAppConfigurations] = useState(false)
  const [busy, setBusy] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  // const onKeyDown = (e) => {
  //   e.keyCode === 13 && onFormSubmit();
  // }
  const redirect = () => {
    props.history.push('/faq')
  }

  const logoutUser = async() => {
    setBusy(true)
    // await props.logoutUser();
    await props.destroySession();
    setBusy(false)
    props.history.replace('/login')
  }
  return (
    <div className={showProfile || showAppConfigurations ? "header headershow" : "header"} >
      <Navbar>
        <Navbar.Brand className='navbar-logo'>
          <img src={Constant.IMAGESURL.LOGO} title="" alt="" className="logo" />
        </Navbar.Brand>

        <div className='profiledropdown'>
          <div className="profilebtn">
            <button className="app_menu" onClick={redirect}>
              <div className="icon-question-circle"></div>
              <span className="txt">FAQ</span>
            </button>



            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="avtar-name">
                <div className="avtar">
                  <img src= {props.adminDetails && 
                    props.adminDetails?.profileImg 
                    ? props.adminDetails.profileImg
                    : "https://res.cloudinary.com/zudu/image/upload/v1650348197/Safer-Walking/Web-App/Project-images/92456aed862ab705f27e39a2ea46f702_kjvvvz.jpg"}
                     title="" alt="" className="logo" />
                  {/* <span className="sortName">MD</span> */}
                </div>
                <div className="profile_details_name">
                  <span className="name">{props.adminDetails?.name}</span>
                  <span className="email">{props.adminDetails?.email}</span>
                </div>
                <div className="icon-chevron-down-small"></div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/profile"><div className="cricle_box"><i className="icon-user-cricle"></i></div><div className="menulink">{strings["ACCOUNTSETTING"]}</div><i className="icon-chevron_big_right"></i></Dropdown.Item>
                <Dropdown.Item href="/changepassword"><div className="cricle_box"><i className="icon-key"></i></div><div className="menulink">{strings["CHANGEPASSWORD"]}</div><i className="icon-chevron_big_right"></i></Dropdown.Item>
                <Dropdown.Item onClick={() => setShowLogoutModal(true)}><div className="cricle_box"><i className="icon-logout"></i></div><div className="menulink">{strings["LOGOUT"]}</div></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>
      </Navbar >
      <ModalPopup
        showModal={showLogoutModal}
        onConfirmClick={logoutUser}
        onCancelClick={() => setShowLogoutModal(false)}
        onHide={() => setShowLogoutModal(false)}
        title={strings["ALERT"]}
        txt={strings["LOGOUTTXT"]}
        confirmTitle={strings["YESLOGOUT"]}
        cancelTitle={strings["NOCANCEL"]}
        message="You have been logout successfully."
        loading={busy}
      />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    adminDetails: state?.auth?.session?.user

  };
};

const mapDispatchToProps = {
  showToast,
  logoutUser,
  destroySession
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(multilanguage(AdminHeader))
);
