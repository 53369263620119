import {
    login as loginAction,
    logout as logoutAction,
    refreshToken,
    authenticated,
    destroySession,
    initializeSession,


} from "./actions";

const login = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(loginAction(payload));
        return response
    };
}

const logout = (payload) => {
    return async (dispatch) => {
        await dispatch(logoutAction());
        await dispatch(destroySession())
    };
}

export {
    login,
    logout,
    refreshToken,
    authenticated,
    destroySession,
    initializeSession,

};
