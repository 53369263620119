import React, { useEffect, useState } from 'react';
import { Fade, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { withRouter } from 'react-router-dom';
import HookForm from '../HookForm/HookForm';
import CustomButton from '../UI/CustomButton/CustomButton';
import { showToast } from "../../state/ducks/utils/operations";
import './ModalPopup.scss';

const ModalPopup = (props) => {
  let { title, txt, confirmTitle, cancelTitle, onConfirmClick, onCancelClick, showModal, className, message, modalMessage, deleteCardError, defaultCardError } = props;
  const [busy, setBusy] = useState(false)
  useEffect(() => {
    setBusy(false)
    modalMessage && modalMessage.msg && props.showToast({
      message: modalMessage.msg,
      type: modalMessage.type
    });
  }, [modalMessage])
  const onFormSubmit = async (e) => {
    try {
      setBusy(true);
      if(deleteCardError){
        setBusy(false)
        props.showToast({
          message: "You are deleting default card of other subscriptions!",
          type: "error"
        });
      }else{
        if (message){
        setTimeout(() => {
          console.log(`message ----->>>>`, message);
          setBusy(false)
          props.showToast({
            message: defaultCardError? 'You cannot update a canceled subscription' : message,
            type: "success"
          });
        }, 2000);
      }}
    } catch (error) {
      setBusy(false)
      props.showToast({
        message: "Something wrong!",
        type: "error"
      });
    }
  }

  return (
    <Modal
      className={'modalPopup ' + className}
      show={showModal}
      animation={Fade}
      centered
      onHide={() => props.onHide()}
    >
      <HookForm
        defaultForm={{}}
        init={modal => modal}
        onSubmit={(e) => onFormSubmit(e)}>
        {(formMethod) => {
          return (
            <>
              <div className='modal-header'>
                {title}
              </div>
              <Modal.Body>
                {txt}
              </Modal.Body>
              <div className='modal-footer'>
                <CustomButton type="submit" title={confirmTitle} className='delete' onClick={onConfirmClick} loading={busy}/>
                <CustomButton type="button" title={cancelTitle} onClick={onCancelClick} />
              </div>
            </>
          )
        }}
      </HookForm>
    </Modal>
  )

}
ModalPopup.defaultProps = {
  className: 'modalPopup',
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = {
  showToast
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(multilanguage(ModalPopup)));