import { get } from "lodash";
import { combineReducers } from "redux";
import { createReducer } from "../../utils";
import * as types from './types';


const getBusinessList = createReducer(false)({
    [types.GET_BUSINESS_LIST_COMPLETED]: (state, action) => {
        return get(action, 'paylod', [])
    }
})

const deleteBusiness = createReducer(false)({
    [types.DELETE_BUSINESS_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const businessStatusUpate = createReducer(false)({
    [types.BUSINESS_STATUS_UPDATE_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const updateSideMenu = createReducer(false)({
    [types.UPDATE_SIDEMENU]: (state, action) => {
        const updateSideMenu = action.meta.updateSideMenu || false
        return {
            updateSideMenu: updateSideMenu
        }
    }
})

const updateBusiness = createReducer(false)({
    [types.UPDATE_BUSINESS_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})


const addBusiness = createReducer(false)({
    [types.ADD_BUSINESS_COMPLTED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const getServiceDetails = createReducer(false)({
    [types.GET_BUSINESS_ITEM_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})


export default combineReducers({
    businessList: getBusinessList,
    deleteBusiness: deleteBusiness,
    businessStatusUpdate: businessStatusUpate,
    updateSideMenu: updateSideMenu,
    updateBusiness: updateBusiness,
    addBusiness: addBusiness,
    getServiceDetails: getServiceDetails
})