import moment from "moment";
import { isEmpty } from "lodash"
// import fetch from "../state/utils/fetch";
export const isValidValue = (value) => {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return false
  }
  return true
}
export const getReleaseTime = timestamp => {
  timestamp = timestamp * 1000;
  let dt = new Date(timestamp);
  return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
    "hh:mm A"
  )}`;
};

export const getTimelineTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return moment(dt).fromNow();
  } else {
    return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
      "hh:mm A"
    )}`;
  }
};

export const UID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

export const dateFormat = (date, format = 'YYYY/MM/DD') => {
  return moment(date).format(format)
}

export const dateFormatWithDate = (date) => {
  return moment(date).format("DD MMM, YYYY hh:mmA")
}


export const getParams = (params) => {
  if (isEmpty(params)) return ''
  let query = []
  Object.keys(params).forEach(key => {
    if (isValidValue(params[key])) {
      query.push(`${key}=${params[key]}`)
    }
  })
  return query.join('&')
}

export const getDaysLeft = timestamp => {
  if(timestamp === null) {
    return false
  }
  const date = moment.unix(timestamp).format("YYYY-MM-DD");
  let a = moment(date, "YYYY-MM-DD");
  let b = moment().startOf('day')
  const c = moment.duration(a.diff(b)).asDays();
  return c
}

export const dateFromTimeline = timestamp => {
  if(timestamp === null) {
    return false
  }
  const date = moment.unix(timestamp).format("DD/MM/YYYY");
  return date
};

export const dateFromTimelineFormate = timestamp => {
  if(timestamp === null) {
    return false
  }
  const date = moment.unix(timestamp).format("DD MMM, YYYY hh:mmA");
  return date
};

export const extractTime = data => {
  if(data === null) {
    return false
  }
  const time = moment(data).format("HH:mm:ss");
  return time
};

export const extractDate = data => {
  if(data === null) {
    return false
  }
  const date = moment(data).format("YYYY/MM/DD");
  return date
};

export const concatDateTime = (data, time) => {
  if(data === null || time === null) {
    return false
  }
  const dd = new Date(data + ' ' + time)
  const dateTime = new Date(dd).toISOString();
  return dateTime
};

export function isCheckValueAndSetParams(params, value) {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return ''
  }
  return params + value
}

export function  getLocationAddress(lat, lng){
  return new Promise((resolve, reject) => {
    var latlng = new window.google.maps.LatLng(lat, lng);
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          resolve(results[1].formatted_address)
          return;
        }
      }
    });
  })
  
}

export const checkTwoDateEqual = (start, end) => {
  if(start === null && end === null) {
    return false
  }

  const startDateMonth = moment(start).format("DDMMYYYY");
  const endDateMonth = moment(end).format("DDMMYYYY");
  if(startDateMonth === endDateMonth) {
    return true
  }else {
    return false
  }
};