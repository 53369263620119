import { isEmpty } from "lodash"
export const getParams = (params) => {
    if (isEmpty(params)) return ''
    let query = []
    Object.keys(params).forEach(key => {
        if (params[key] !== '') {
            query.push(`${key}=${params[key]}`)
        }
    })
    return query.join('&')
}