export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const EMAIL_VERIFY = "auth/EMAIL_VERIFY"

export const SIGNUP = "SIGNUP";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_COMPLETED = "SIGNUP_COMPLETED";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";

export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";

export const FORGOT_PASSWORD = "auth/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILED = "auth/FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_COMPLETED = "auth/FORGOT_PASSWORD_COMPLETED";

export const VERIFY_RESET_PASSWORD_LINK = "auth/VERIFY_RESET_PASSWORD_LINK";
export const VERIFY_RESET_PASSWORD_LINK_FAILED = "auth/VERIFY_RESET_PASSWORD_LINK_FAILED";
export const VERIFY_RESET_PASSWORD_LINK_COMPLETED = "auth/VERIFY_RESET_PASSWORD_LINK_COMPLETED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";
export const CHECK_OLD_PASSWORD = "auth/CHECK_OLD_PASSWORD"

export const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD"

export const GET_SELLER = "auth/GET_SELLER"

export const GET_FAQS = "auth/GET_FAQS"

export const BUY_DEVICE = "user/BUY_DEVICE"

export const RE_SUBSCRIBE_DEVICE = "user/RE_SUBSCRIBE_DEVICE"

export const GET_USER_INFO = "user/GET_USER_INFO"

export const GET_DEVICE = "user/GET_DEVICE"

export const GET_DEVICE_COMPLETED = "user/GET_DEVICE_COMPLETED"

export const UPDATE_DEVICE_INFO = "user/UPDATE_DEVICE_INFO"

export const SAVE_DEVICE_TO_FISPI = "user/SAVE_DEVICE_TO_FISPI"

export const DESTROY = "auth/DESTROY_SESSION";

export const INITIALIZE = "auth/INITIALIZE_SESSION";

export const PROFILE_UPDATE = "auth/PROFILE_UPDATE";

export const PROFILE_UPDATE_COMPLETE = "auth/PROFILE_UPDATE_COMPLETE";

export const GET_DEVICE_LOGS = "user/GET_DEVICE_LOGS"

export const CREATE_DEVICES = "user/CREATE_DEVICES"
export const CREATE_DEVICES_COMPLETE = "user/CREATE_DEVICES_COMPLETE"

export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION"
export const SET_CURRENT_LOCATION_COMPLETE = "SET_CURRENT_LOCATION"

export const GET_DEVICE_PAYMENT_INFO = "user/GET_DEVICE_PAYMENT_INFO"
export const GET_DEVICE_PAYMENT_INFO_COMPLETED = "user/GET_DEVICE_PAYMENT_INFO_COMPLETED"

export const CANCEL_DEVICE_SUBSCRIPTION = "user/CANCEL_DEVICE_SUBSCRIPTION"

export const UPDATE_SUBSCRIPTION_PRICE = "user/UPDATE_SUBSCRIPTION_PRICE"

export const GET_CUSTOMER_PAYMENT_METHODS = "user/GET_CUSTOMER_PAYMENT_METHODS"

export const GET_CUSTOMER_PAYMENT_METHODS_COMPLETED = "user/GET_CUSTOMER_PAYMENT_METHODS_COMPLETED"

export const GET_CUSTOMER_PAYMENT_INVOICES = "user/GET_CUSTOMER_PAYMENT_INVOICES"

export const SET_CUSTOMER_METHOD_PAYMENT = "user/SET_CUSTOMER_METHOD_PAYMENT"

export const DELETE_CUSTOMER_METHOD_PAYMENT = "user/DELETE_CUSTOMER_METHOD_PAYMENT"

export const ADD_CUSTOMER_METHOD_PAYMENT = "user/ADD_CUSTOMER_METHOD_PAYMENT"

export const DELETE_SETTING_UPDATE = "user/DELETE_SETTING_UPDATE"

export const GET_DEVICE_HISTORY = "user/GET_DEVICE_HISTORY"

export const GET_DEVICE_EVENTS = "user/GET_DEVICE_EVENTS"

export const NOTIFY_USER = "user/NOTIFY_USER"

export const USER_SUBSCRIPTION = "user/USER_SUBSCRIPTION"

export const GET_SUBSCRIPTION = "user/GET_SUBSCRIPTION"

export const UPDATE_RESUBSCRIBE_DEVICE = "user/UPDATE_RESUBSCRIBE_DEVICE"

export const UPDATE_OVERDUE_DEVICE = "user/UPDATE_OVERDUE_DEVICE"

export const PRODUCT_PRICE = "user/PRODUCT_PRICE"