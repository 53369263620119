export const POST_NOTIFICATION_LIST = "POST_NOTIFICATION_LIST";
export const POST_NOTIFICATION_LIST_COMPLETED = "POST_NOTIFICATION_LIST_COMPLETED";

export const SHOW_NOTIFICATION_FILTER = "SHOW_NOTIFICATION_FILTER";
export const HIDE_NOTIFICATION_FILTER = "HIDE_NOTIFICATION_FILTER";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION";
export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
