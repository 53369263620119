import PubNub from 'pubnub'
import StorageService from '../services/localstorage.service'
const uuid = PubNub.generateUUID()

export const pubnub = new PubNub({
  publishKey: 'pub-c-3f548cb4-2832-4ade-af04-461555352742',
  subscribeKey: 'sub-c-4f8b22e9-34fe-4fbf-a3fc-ca140402f27e',
  uuid: uuid,
})

export const addListener = (client, callback) => {
  client &&
    client.addListener({
      message: (messageEvent) => {
        callback && callback(messageEvent.message)
      },
    })
}

export const subscribe = (client, user) => {
    if (user) {
        let channel = user.id
        client && pubnub.subscribe({ channels: [channel] });
    }
}

export const unsubscribe = (client) => {
    let user = StorageService.getItem("user")
    if (user) {
        let channel = user.id
        client && pubnub.unsubscribe({ channels: [channel] });
    }
}
