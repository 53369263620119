import * as types from "./types";


export const login = (payload) => (
    {
        type: types.LOGIN,
        meta: {
            async: true,
            blocking: false,
            path: "auth/UserLogin",
            method: "POST",
            body: payload
        },
    });

export const logoutUser = () => {
    console.log("calling log out");
    return ({
    type: types.LOGOUT,
    meta: {
        async: true,
        blocking: true,
        path: 'auth/userlogout',
        method: 'POST',
        body: {},
    },
})}


export const emailVerification = (token) => (
    {
        type: types.EMAIL_VERIFY,
        meta: {
            async: true,
            blocking: false,
            path: `auth/user-verify/${token}`,
            method: "POST",

        },
    });

export const signup = (payload) => ({
    type: types.SIGNUP,
    meta: {
        async: true,
        blocking: true,
        path: `auth/userRegistration`,
        method: "POST",
        body: payload
    },
});

export const updateProfile = (payload) => ({
    type: types.PROFILE_UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: `auth/update-user`,
        method: "PATCH",
        body: payload
    },
});

export const authenticated = () => ({
    type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = () => ({
    type: types.DESTROY
});

export const refreshToken = () => ({
    type: types.REFRESH_TOKEN
})

export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/forgot-password",
        method: "POST",
        body: payload
    },
});

export const resetPassword = (token, payload) => ({
    type: types.RESET_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: `auth/reset-password/${token}`,
        method: "PUT",
        body: payload
    },
});

export const verifyResetPasswordLink = (token) => ({
    type: types.VERIFY_RESET_PASSWORD_LINK,
    meta: {
        async: true,
        blocking: true,
        path: `auth/check-token/${token}`,
        method: "POST"
    },
});

export const oldPaasswordCheck = (payload) => ({
    type: types.CHECK_OLD_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/checkOldPassword",
        method: "POST",
        body: payload
    },
});
export const changePassword = (payload) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/changepassword",
        method: "PUT",
        body: payload
    },
});

export const getSellerList = () => ({
    type: types.GET_SELLER,
    meta: {
        async: true,
        blocking: true,
        path: "user/getseller",
        method: "GET",
    },
});

export const getFaqList = () => ({
    type: types.GET_FAQS,
    meta: {
        async: true,
        blocking: true,
        path: "user/getfaq",
        method: "GET",
    },
});

export const buyDevice = (payload) => ({
    type: types.BUY_DEVICE,
    meta: {
        async: true,
        blocking: true,
        path: "user/subscription",
        method: "POST",
        body: payload
    },
});
export const GetUserInfo = () => ({
    type: types.GET_USER_INFO,
    meta: {
        async: true,
        blocking: true,
        path: "user/getUser",
        method: "GET",
    },
});


export const getDevices = () => ({
    type: types.GET_DEVICE,
    meta: {
        async: true,
        blocking: true,
        path: "user/get-device",
        method: "GET",
    },
});

export const updateDeviceInfo = (payload, id) => ({
    type: types.UPDATE_DEVICE_INFO,
    meta: {
        async: true,
        blocking: true,
        path: `user/device-update/${id}`,
        method: "PATCH",
        body: payload
    },
});

export const getDevicesLogs = (id, device_id) => ({
    type: types.GET_DEVICE_LOGS,
    meta: {
        async: true,
        blocking: true,
        path: `user/device-logs/${id}?deviceId=${device_id}`,
        method: "GET",
    },
});

export const createNewDevices = (payload) => ({
    type: types.CREATE_DEVICES,
    meta: {
        async: true,
        blocking: true,
        path: `user/create-device`,
        method: "POST",
        body: payload
    },
});

export const devicePaymentInfo = (deviceSubId) => ({
    type: types.GET_DEVICE_PAYMENT_INFO,
    meta: {
        async: true,
        blocking: true,
        path: `user/device-payment-info/${deviceSubId}`,
        method: "GET",
    },
});

export const cancelItemSubs = (payload) => ({
    type: types.CANCEL_DEVICE_SUBSCRIPTION,
    meta: {
        async: true,
        blocking: true,
        path: `user/delete-subscription`,
        method: "POST",
        body: payload
    },
});

export const updateSubsPrice = (payload) => ({
    type: types.UPDATE_SUBSCRIPTION_PRICE,
    meta: {
        async: true,
        blocking: true,
        path: `user/updateSubsPrice`,
        method: "POST",
        body: payload
    },
});

export const customerPaymentMethods = (customerId) => ({
    type: types.GET_CUSTOMER_PAYMENT_METHODS,
    meta: {
        async: true,
        blocking: true,
        path: `user/customer-payment-methods/${customerId}`,
        method: "GET",
    },
});

export const customerPaymentInvoices = (paymentId) => ({
    type: types.GET_CUSTOMER_PAYMENT_INVOICES,
    meta: {
        async: true,
        blocking: true,
        path: `user/customer-payment-invoice/${paymentId}`,
        method: "GET",
    },
});

export const changeDefaultPayment = (data) => ({
    type: types.SET_CUSTOMER_METHOD_PAYMENT,
    meta: {
        async: true,
        blocking: true,
        path: `user/customer-default-payment`,
        method: "POST",
        body: data
    },
});

export const deletePaymentMethod = (id) => ({
    type: types.DELETE_CUSTOMER_METHOD_PAYMENT,
    meta: {
        async: true,
        blocking: true,
        path: `user/customer-delete-payment/${id}`,
        method: "DELETE",
    },
});

export const addPaymentMethod = (data) => ({
    type: types.ADD_CUSTOMER_METHOD_PAYMENT,
    meta: {
        async: true,
        blocking: true,
        path: `user/customer-add-payment`,
        method: "POST",
        body: data
    },
});

export const deviceSettingUpdate = (id) => ({
    type: types.DELETE_SETTING_UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: `user/update-setting/${id}`,
        method: "POST",
    },
});

export const deviceHistory = (data) => ({
    type: types.GET_DEVICE_HISTORY,
    meta: {
        async: true,
        blocking: true,
        path: data.from && data.to ? `user/device-history?from=${data.from}&to=${data.to}&deviceId=${data.deviceId}` : `user/device-history?deviceId=${data.deviceId}`,
        method: "GET",
    },
});

export const deviceEvents = (id) => ({
    type: types.GET_DEVICE_EVENTS,
    meta: {
        async: true,
        blocking: true,
        path: `user/getevents/${id}`,
        method: "GET",
    },
});

export const NotifyUser = (data) => ({
    type: types.NOTIFY_USER,
    meta: {
        async: true,
        blocking: true,
        path: `user/notification`,
        method: "POST",
        body: data
    },
});

export const setCurrentLocation = (payload) => ({
    type: types.SET_CURRENT_LOCATION,
    payload: payload
});

export const getUserCurrentSubs = (data) => ({
    type: types.USER_SUBSCRIPTION,
    meta: {
        async: true,
        blocking: true,
        path: `user/all-subscriptions`,
        method: "POST",
        body: data
    },
});

export const getSubscription = (id) => ({
    type: types.GET_SUBSCRIPTION,
    meta: {
        async: true,
        blocking: true,
        path: `user/subscriptions-data`,
        method: "POST",
        body: id
    },
});

export const getProductPriceDetails = (data) => ({
    type: types.PRODUCT_PRICE,
    meta: {
        async: true,
        blocking: true,
        path: `user/stripe-product-details`,
        method: "POST",
        body: data
    },
});

export const updateSubscription = (data) => ({
    type: types.USER_SUBSCRIPTION,
    meta: {
        async: true,
        blocking: true,
        path: `user/update-subscriptions`,
        method: "POST",
        body: data
    },
});

export const reSubscribeDevice = (payload) => ({
    type: types.RE_SUBSCRIBE_DEVICE,
    meta: {
        async: true,
        blocking: true,
        path: "user/re-subscription",
        method: "POST",
        body: payload
    },
});

export const updateReSubscribeDevice = (payload) => ({
    type: types.UPDATE_RESUBSCRIBE_DEVICE,
    meta: {
        async: true,
        blocking: true,
        path: `user/update-device-resubscribe`,
        method: "POST",
        body: payload
    },
});

export const updateOverdueDevice = (payload) => ({
    type: types.UPDATE_OVERDUE_DEVICE,
    meta: {
        async: true,
        blocking: true,
        path: `user/update-device-overdue`,
        method: "POST",
        body: payload
    },
});