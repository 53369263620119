import { lazy } from "react";
import AdminLayout from "./layout/AdminLayout/index";

const AdminLogin = lazy(() =>
    import(/* webpackChunkName: "login" */ /* webpackPreload: true */ "./pages/Admin/Login"));

const ForgotPassword = lazy(() =>
    import(/* webpackChunkName: "forgotpassword" */ /* webpackPreload: true */ "./pages/Admin/ForgotPassword"));

const ResetPassword = lazy(() =>
    import(/* webpackChunkName: "resetpassword" */ /* webpackPreload: true */ "./pages/Admin/ResetPassword"));
const ChangePassword = lazy(() =>
    import(/* webpackChunkName: "changepassword" */ /* webpackPreload: true */ "./pages/Admin/ChangePassword"));

const SignUp = lazy(() =>
    import(/* webpackChunkName: "signup" */ /* webpackPreload: true */ "./pages/Admin/SignUp"));
const BuyDevice = lazy(() =>
    import(/* webpackChunkName: "buydevice" */ /* webpackPreload: true */ "./pages/Admin/BuyDevice"));

const Profile = lazy(() =>
    import(/* webpackChunkName: "profile" */ /* webpackPreload: true */ "./pages/Admin/Profile"));

const AddDevice = lazy(() =>
    import(/* webpackChunkName: "addprofile" */ /* webpackPreload: true */ "./pages/Admin/AddDevice"));
const Faq = lazy(() =>
    import(/* webpackChunkName: "faq" */ /* webpackPreload: true */ "./pages/Admin/Faq"));

const Home = lazy(() =>
    import(/* webpackChunkName: "home" */ /* webpackPreload: true */ "./pages/Admin/Home"));

const UserConformation = lazy(() =>
    import(/* webpackChunkName: "notfound" */ /* webpackPreload: true */ "./pages/Admin/UserConformation"));

const NotFound = lazy(() =>
    import(/* webpackChunkName: "notfound" */ /* webpackPreload: true */ "./pages/NotFound"));

const routes = [
    {
        path: "/",
        component: AdminLogin,
        exact: true,
    },
    {
        path: "/login",
        component: AdminLogin,
        exact: true,
    },
    {
        path: "/forgotpassword",
        component: ForgotPassword,
        exact: true,
    },
    {
        path: "/resetpassword",
        component: ResetPassword,
        exact: true,
    },
    {
        path: "/changepassword",
        component: ChangePassword,
        exact: true,
    },
    {
        path: "/signup",
        component: SignUp,
        exact: true,
    },
    {
        path: "/buydevice",
        component: BuyDevice,
        exact: true,
    },

    {
        path: "/profile",
        component: Profile,
        exact: true,
    },
    {
        path: "/adddevice",
        component: AddDevice,
        exact: true,
    },
    {
        path: "/faq",
        component: Faq,
        exact: true,
    },
    {
        path: "/Home",
        component: AdminLayout,
        exact: true
    },
    {
        path: "/userConformation",
        component: UserConformation,
        exact: true
    },
    {
        path: "*",
        component: NotFound,
        exact: true
    }
]


export const AdminLayoutRoute = [
    {
        path: "/home",
        component: Home,
        exact: true
    },
]

export default routes