
export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";


export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";



export const DESTROY = "auth/DESTROY_SESSION";
export const INITIALIZE = "auth/INITIALIZE_SESSION";