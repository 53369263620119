import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const filterInitialState = {
    show: false,
    filters: {
        fromDate: '',
        endDate: ''
    }
}

const totalNotificationReducer = createReducer(0)({
    [types.POST_NOTIFICATION_LIST_COMPLETED]: (state, action) => get(action, 'payload.payload.total', 0)
});

const listNotificationReducer = createReducer([])({
    [types.POST_NOTIFICATION_LIST_COMPLETED]: (state, action) => get(action, 'payload.payload.data', []),
});

const unreadNotificationReducer = createReducer(0)({
    [types.POST_NOTIFICATION_LIST_COMPLETED]: (state, action) => {
        let list = get(action, 'payload.payload.data', [])
        let unread = list.filter((l) => l.read === false)
        return unread.length
    }
});

const filterNotificationReducer = createReducer(filterInitialState)({
    [types.SHOW_NOTIFICATION_FILTER]: (state, action) => ({ show: true, ...action.payload }),
    [types.HIDE_NOTIFICATION_FILTER]: (state, action) => ({ show: false, ...action.payload }),
});

export default combineReducers({
    total: totalNotificationReducer,
    list: listNotificationReducer,
    filters: filterNotificationReducer,
    unread: unreadNotificationReducer
});
