import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import JWTService from "./../../../services/jwt.service";
import StorageService from "./../../../services/localstorage.service"

const authReducer = createReducer(false)({
    [types.LOGOUT]: () => false,
    [types.AUTHENTICATED]: () => true,
    [types.AUTHORIZATION_FAILED]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        StorageService.removeItem('token');
        return false
    }
});
const loginCompletedReducer = createReducer({})(
    {
        [types.LOGIN_COMPLETED]: (state, action) => {
            return get(action, 'payload.payload', {})
        }
    });

const getDeviceReducer = createReducer([])(
    {
        [types.GET_DEVICE_COMPLETED]: (state, action) => {
            return get(action, 'payload.payload', [])
        }
    });
const initializeSessionReducer = createReducer(null)({
    [types.INITIALIZE]: (state, action) => {
        const user = action.payload.user;
        const tokens = action.payload.tokens;
        StorageService.setItem('isAuthenticated', true);
        StorageService.setItem('user', user);
        JWTService.saveToken(tokens);
        return { user, tokens }
    },
    [types.DESTROY]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        JWTService.destroyToken();
        return null;
    }
});

const setCurrentLocationReducer = createReducer({})(
    {
        [types.SET_CURRENT_LOCATION_COMPLETE]: (state, action) => {
            
            const location = action.payload
            StorageService.setItem('currentLocation', location);
            return {location}
        }
    });

const createNewDevicesReducer = createReducer({})({
    [types.CREATE_DEVICES_COMPLETE]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});

const devicePaymentInfoReducer = createReducer({})({
    [types.GET_DEVICE_PAYMENT_INFO_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});

const customerPaymentMethodsReducer = createReducer({})({
    [types.GET_CUSTOMER_PAYMENT_METHODS_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});

const updateProfileReducer = createReducer({})({
    [types.PROFILE_UPDATE_COMPLETE]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});

export default combineReducers({
    isAuthenticated: authReducer,
    session: initializeSessionReducer,
    loginDetails: loginCompletedReducer,
    getDeviceReducer: getDeviceReducer,
    currentLocation: setCurrentLocationReducer,
    createNewDevices: createNewDevicesReducer,
    devicePaymentInfo: devicePaymentInfoReducer,
    customerPaymentMethods: customerPaymentMethodsReducer,
    updateProfile: updateProfileReducer
});
