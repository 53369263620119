import { get } from "lodash";
import { combineReducers } from "redux";
import { createReducer } from "../../utils";
import * as types from './types';



const categoryReducer = createReducer(false)({
    [types.GET_CATEGORY_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const categoryStatusUpdate = createReducer(false)({
    [types.STATUS_UPDATE_CATEGORY_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const deleteCategory = createReducer(false)({
    [types.DELETE_CATEGORY_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const updateCategory = createReducer(false)({
    [types.UPDATE_CATEGORY_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const addCategory = createReducer(false)({
    [types.ADD_CATEGORY_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

export default combineReducers({
    categoryList: categoryReducer,
    statusUpdateCategory: categoryStatusUpdate,
    deleteCategory: deleteCategory,
    updateCategory: updateCategory,
    addCategory: addCategory
})