import {
    login as loginAction,
    emailVerification,
    signup,
    refreshToken,
    resetPassword,
    oldPaasswordCheck,
    changePassword,
    getSellerList,
    buyDevice,
    GetUserInfo,
    getDevices,
    updateDeviceInfo,
    authenticated,
    destroySession,
    forgotPassword,
    initializeSession,
    verifyResetPasswordLink,
    getDevicesLogs,
    setCurrentLocation,
    createNewDevices,
    devicePaymentInfo,
    cancelItemSubs,
    customerPaymentMethods,
    customerPaymentInvoices,
    changeDefaultPayment,
    deletePaymentMethod,
    addPaymentMethod,
    deviceHistory,
    deviceSettingUpdate,
    NotifyUser,
    updateProfile,
    getFaqList,
    deviceEvents,
    logoutUser,
    getUserCurrentSubs,
    getSubscription,
    updateSubscription,
    getProductPriceDetails,
    reSubscribeDevice,
    updateReSubscribeDevice,
    updateOverdueDevice,
    updateSubsPrice
} from "./actions";

const login = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(loginAction(payload));
        return response
    };
}

export {
    login,
    emailVerification,
    refreshToken,
    resetPassword,
    authenticated,
    signup,
    oldPaasswordCheck,
    changePassword,
    getSellerList,
    buyDevice,
    GetUserInfo,
    getDevices,
    updateDeviceInfo,
    destroySession,
    forgotPassword,
    initializeSession,
    verifyResetPasswordLink,
    getDevicesLogs,
    setCurrentLocation,
    createNewDevices,
    devicePaymentInfo,
    cancelItemSubs,
    customerPaymentMethods,
    customerPaymentInvoices,
    changeDefaultPayment,
    deletePaymentMethod,
    addPaymentMethod,
    deviceHistory,
    deviceSettingUpdate,
    NotifyUser,
    updateProfile,
    getFaqList,
    deviceEvents,
    logoutUser,
    getUserCurrentSubs,
    getSubscription,
    updateSubscription,
    getProductPriceDetails,
    reSubscribeDevice,
    updateReSubscribeDevice,
    updateOverdueDevice,
    updateSubsPrice
};
